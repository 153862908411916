<template>
  <!-- <div class="SlowMovingLocationItem"> -->
      <div class="content-card ">
        <div v-show="data.station_a.id != data.station_b.id" class="card-header rounded-t-lg bg-brand card-header-flex-center grid grid-cols-1 xl:grid-cols-3 p-2 items-center justify-center text-center font-bold">
          <p>{{data.station_a.name}} ({{data.station_a.shortname}})</p>
          <span v-if="data.direction == 'B'"> <span class="hidden xl:block">←</span><span class="xl:hidden block">↑</span> </span>
          <span v-if="data.direction == 'A'"> <span class="hidden xl:block">→</span><span class="xl:hidden block">↓</span> </span>
          <span v-if="data.direction == 'AB'"> <span class="hidden xl:block">⇆</span><span class="xl:hidden block">⇅
</span> </span>
          <!-- <img v-if="data.direction == 'AB'" src="@/assets/Linie_LF_rechts.png"> -->
          <p>{{data.station_b.name}} ({{data.station_b.shortname}})</p>
        </div>
        <div v-show="data.station_a.id == data.station_b.id" class="card-header rounded-t-lg bg-brand card-header-flex-center grid grid-cols-1 p-2 items-center justify-center text-center font-bold">
          <p>{{data.station_a.name}} ({{data.station_a.shortname}})</p>
        </div>
        <div class="card-content rounded-b-lg bg-card-content">
          <div class="text-center grid grid-cols-1 md:grid-cols-2 gap-3 p-4 items-center justify-center">
            <div class="col-6"><b>Aktuelle Geschwindigkeit</b><br><span class="text-lg">{{data.speed_slow}}km/h</span></div>
            <div class="col-6"><b>Reguläre Geschwindigkeit</b><br><span class="text-lg">{{data.speed_normal}}km/h</span></div>
            <div class="col-6" v-if="data.reason"><b>Grund</b><br><span class="text-lg">{{data.reason.text}}</span></div>
            <div class="col-6"><b>Reguläre Linien</b><br>
              <!-- {{data.lines}} -->
              <span class="text-lg" v-for="lines in data.lines" :key="lines.id">
                {{lines.lines_id.name}}
              </span>
            </div>
          </div>
          <div class="flex justify-end">
          <button class="button-filled mx-2 mb-2" @click="openReportModal" v-show="reportable"><font-awesome-icon icon="flag" class="icon" /></button>
          </div>
        </div>
        
      </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "SlowMovingLocationItem",
  props: {
    data: null,
    direction: Number,
    file: String,
    speed_slow: String,
    speed_normal: String,
    distance: String,
    date_begin: String,
    date_end: String,
    station_a: String,
    station_b: String,
    canEdit: Boolean,
    reportable: false,
  },
  methods: {
    openReportModal(){
      this.$emit('openReportModal',this.data);
    }
  },
};
</script>
