<template>
  <div class="NoDataBox">
    <div class="nodata-container text-center my-20">
      <!-- <font-awesome-icon icon="frown" class="icon brand-color-dark text-6xl" /> -->
      <p class="nodata-heading text-2xl mt-5 mb-2">{{ headline }}</p>
      <p>
        {{ text }}
      </p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoDataBox",
  props: {
    headline: String,
    text: String,
  },
};
</script>